import { useCallback, useRef, useState } from 'react';
import { ESSortOrder } from 'enums/ESSortOrder';

const useSorting = () => {
    const sortRulesRef = useRef<{ elementId: string; order: string }[]>([]);
    const [sortRules, setSortRules] = useState<{ elementId: string; order: string }[]>([]);

    const handleSorting = (elementId: string, order: string) => {
        const sortRules = updateSortRules(elementId, order);
        setSortRules(sortRules);
    };

    const updateSortRules = useCallback((elementId: string, order: string) => {
        if (sortRulesRef.current.findIndex(e => e.elementId === elementId) === -1) {
            sortRulesRef.current = [...sortRulesRef.current, ...[{ elementId: elementId, order }]];
            return sortRulesRef.current;
        } else {
            sortRulesRef.current = sortRulesRef.current
                .map(x => (x.elementId === elementId ? { ...x, order: order } : x))
                .filter(x => x.order !== ESSortOrder.none);
            return sortRulesRef.current;
        }
    }, []);

    return { orderBy: sortRules, handleSorting };
};

export default useSorting;
