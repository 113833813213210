import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useManageCustomersTable from './useManageCustomersTable';
import useCheckboxes from 'components/Shared/Table/useCheckboxes';
import CheckAllButton from 'components/Shared/Table/CheckAllButton';
import { IManageCustomersTable } from '../types';
import { faEllipsis, faFilter as faFilterSolid } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { manageCustomersdefaultSettings } from './ManageCustomersConstants';
import CheckBoxDropdown from 'components/Shared/CheckboxDropdown/CheckboxDropdown';
import { faFilter as faFilterRegular } from '@fortawesome/pro-regular-svg-icons';

const ManageCustomersTable = ({
    tableId,
    tableClassName = '',
    isTableRowsClickable = false,
    columnDetails,
    data,
    noDataMessage = 'No data found to display',
    sortClickCallback,
    rowClickCallback,
    filterChangeCallback,
    selected,
    setSelected,
    idKey,
    ellipsisColumnIndex = null,
    options,
    selectedFilters,
}: IManageCustomersTable) => {
    const settings = useMemo(() => ({ ...manageCustomersdefaultSettings, ...(options || {}) }), [options]);

    const { checkAllState, toggleCheckAll, toggleCheck } = useCheckboxes(
        'userId',
        data,
        selected,
        setSelected,
        settings
    );

    const {
        sortedPropertyKey,
        sortedPropertyDirection,
        handleSortableTableHeaderClick,
        handleRowClick,
        handleFiltersChange,
    } = useManageCustomersTable(isTableRowsClickable, sortClickCallback, rowClickCallback, filterChangeCallback);

    if (data.length === 0) return <h4 className="d-flex justify-content-center mt-3">{noDataMessage}</h4>;
    return (
        <table id={tableId} className={`table ${isTableRowsClickable && 'table-hover'} ${tableClassName}`}>
            <thead>
                <tr>
                    {settings.checkboxes && (
                        <th id="checkboxes">
                            <CheckAllButton checkAllState={checkAllState} toggleCheckAll={toggleCheckAll} />
                        </th>
                    )}
                    {columnDetails.map(cd => (
                        <th
                            className={`${cd.sortable && 'clickable'}`}
                            key={cd.columnProperty}
                            onClick={() => cd.sortable && handleSortableTableHeaderClick(cd.columnProperty)}>
                            <div className="d-flex align-items-center">
                                {sortedPropertyKey !== null && sortedPropertyKey === cd.columnProperty && (
                                    <FontAwesomeIcon
                                        className="me-1"
                                        icon={sortedPropertyDirection === 'A' ? 'sort-up' : 'sort-down'}
                                    />
                                )}
                                <span>{cd.title}</span>
                                {cd.filterable && (
                                    <div
                                        className="column-header-icon"
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}>
                                        <CheckBoxDropdown
                                            allOptionEnabled={true}
                                            optionsSearchEnabled={cd.filterConfig.enableSearch}
                                            dropdownBtn={<FontAwesomeIcon icon={faFilterRegular as IconProp} />}
                                            activeDropdownBtn={<FontAwesomeIcon icon={faFilterSolid as IconProp} />}
                                            handleSelectionChange={options => {
                                                handleFiltersChange(cd.textProperty ?? cd.columnProperty, options);
                                            }}
                                            options={cd.filterConfig.filterOptions}
                                            defaultSelected={
                                                selectedFilters.find(
                                                    f =>
                                                        f.columnName == cd.textProperty ||
                                                        f.columnName == cd.columnProperty
                                                )?.optionsSelected ?? []
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((d, index) => (
                    <tr
                        key={index}
                        className={`${isTableRowsClickable && 'clickable'}`}
                        onClick={() => handleRowClick(d)}>
                        {settings.checkboxes && (
                            <td headers="checkboxes" className="ps-3">
                                <input
                                    type="checkbox"
                                    className="scale2x text-primary company-users-table-checkboxes"
                                    checked={selected && selected.some(s => s.id === d[idKey])}
                                    disabled={settings.disableCheckboxFn(d)}
                                    onChange={e =>
                                        toggleCheck({
                                            checked: e.target.checked,
                                            id: d[idKey] as string,
                                            index,
                                            item: d,
                                        })
                                    }
                                />
                            </td>
                        )}
                        {columnDetails.map((cd, columnIndex) => {
                            return (
                                <td headers={`${cd.columnProperty}`} key={`${index}-${cd.columnProperty}`}>
                                    <div className="d-flex w-100 justify-content-between align-items-center ">
                                        {d[cd.columnProperty] as string}
                                        {ellipsisColumnIndex && ellipsisColumnIndex == columnIndex && d['actions'] && (
                                            <span
                                                className="ellipsis-button"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faEllipsis as IconProp}
                                                    color="#195283"
                                                    size="sm"
                                                />
                                                <div className="ellipsis-options actions popover">
                                                    {d['actions'] as string}
                                                </div>
                                            </span>
                                        )}
                                    </div>
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ManageCustomersTable;
