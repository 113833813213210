import { ReactModal } from 'oemiq-common';
import UserModal from '../UserModal';
import MultiUserModal from '../MultiUserModal';
import useOrganizationUsers from './useOrganizationUsers';
import { useCallback, useMemo, useRef } from 'react';
import BulkSendConfirmationModalBody from '../CompanyUsers/BulkSendConfirmationModalBody';
import UsersActivationEmailFooter from '../ManageUsers/UsersActivationEmailFooter';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import { messages } from '../../../Shared/Utils/Constants';
import CustomTooltip from '../../../Shared/Utils/CustomTooltip';
import MoveUserModal from '../MoveUserModal/MoveUserModal';
import ManageCustomersTable from '../ManageUsers/ManageCustomersTable';
import GlobalSearchBar from 'components/Shared/GlobalSearchBar/GlobalSearchBar';

const OrganizationUsers = () => {
    const {
        editUser,
        organizationId,
        showModal,
        tableColumnDetails,
        isOpenUserDisable,
        isOpenSendForgotPasswordEmail,
        displayUsers,
        userAccess,
        onSaveOrEdit,
        handleToggle,
        setShowUserDisableModal,
        handleDisableEnable,
        setShowUserForgotPasswordEmailModal,
        handleSendForgotPassword,
        setSearchTerm,
        setShowModal,
        sortClickCallback,
        isOpenDeleteUser,
        setIsOpenDeleteUser,
        handleDeletePendingUser,
        selectedUsers,
        setSelectedUsers,
        handleBulkSendActivationEmail,
        isOrgActive,
        showMoveUserModal,
        handleShowMoveUserModal,
        editedMoveUser,
        hasMoveUserAccess,
        filterChangeCallback,
        selectedFilters,
        searchParamText,
    } = useOrganizationUsers();

    const confirmRef = useRef();

    const openBulkSendConfirmationModal = useCallback(() => {
        confirmRef.current.open(handleBulkSendActivationEmail, {
            body: <BulkSendConfirmationModalBody selectedUsers={selectedUsers} />,
            title: 'Are you Sure?',
        });
    }, [handleBulkSendActivationEmail, selectedUsers]);

    const shouldShowFooter = useMemo(() => selectedUsers.length > 0, [selectedUsers]);

    return (
        <div className="manage-customers-layout-container">
            {(showModal && editUser && (
                <UserModal
                    onSaveOrEdit={onSaveOrEdit}
                    user={editUser}
                    orgId={organizationId}
                    isOpen={showModal}
                    toggle={handleToggle}
                />
            )) || (
                <MultiUserModal
                    onSaveOrEdit={onSaveOrEdit}
                    orgId={organizationId}
                    isOpen={showModal}
                    toggle={handleToggle}
                />
            )}
            {hasMoveUserAccess && showMoveUserModal && (
                <MoveUserModal
                    showMoveUserModal={showMoveUserModal}
                    handleShowMoveUserModal={handleShowMoveUserModal}
                    userDetails={editedMoveUser}
                    onSaveOrEdit={onSaveOrEdit}
                />
            )}
            <ReactModal
                id="confirm-user-disable-enable"
                isOpen={isOpenUserDisable}
                headerComponent={editUser ? <div>{'Are you sure?'}</div> : <></>}
                bodyComponent={
                    editUser ? (
                        editUser.isDeactivated ? (
                            <div>{'Activate ' + editUser.userName}</div>
                        ) : (
                            <div>{'Deactivate ' + editUser.userName}</div>
                        )
                    ) : (
                        <></>
                    )
                }
                footerComponent={
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setShowUserDisableModal(false)}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleDisableEnable}>
                            Confirm
                        </button>
                    </div>
                }
            />
            <ReactModal
                id="confirm-delete-pending-user"
                isOpen={isOpenDeleteUser}
                headerComponent={editUser ? <div>{'Are you sure?'}</div> : <></>}
                bodyComponent={
                    editUser && (
                        <div>{`You are about to delete the User ${editUser.firstName} ${editUser.lastName}. Please confirm.`}</div>
                    )
                }
                footerComponent={
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setIsOpenDeleteUser(false)}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleDeletePendingUser}>
                            Confirm
                        </button>
                    </div>
                }
            />
            <ReactModal
                id="send-forgot-password"
                isOpen={isOpenSendForgotPasswordEmail}
                headerComponent={editUser ? <div>{'Are you sure?'}</div> : <></>}
                bodyComponent={
                    editUser ? (
                        <div>{'Send ' + editUser.userName + ' a forgot email password to ' + editUser.email + '?'}</div>
                    ) : (
                        <></>
                    )
                }
                footerComponent={
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setShowUserForgotPasswordEmailModal(false)}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleSendForgotPassword}>
                            Confirm
                        </button>
                    </div>
                }
            />
            <div className="position-sticky w-100 p-2 manage-customers-nav">
                <div className="nav-bar mt-3">
                    {searchParamText ? (
                        <h6>
                            <strong>Users</strong> search results for '<strong>{searchParamText}</strong>'
                        </h6>
                    ) : (
                        <div className="buttons-container">
                            {!userAccess.hasCustomerSupportRole && (
                                <CustomTooltip
                                    title={!isOrgActive ? messages.organizationDisabledActionNotAllowed : ''}>
                                    <span>
                                        <button
                                            id="org-create-user"
                                            data-testid="org-create-user"
                                            type="button"
                                            className={`btn btn-sm btn-primary h-100 ${
                                                !isOrgActive ? 'cursor-not-allowed' : ''
                                            }`}
                                            onClick={() => setShowModal(!showModal)}
                                            disabled={!isOrgActive}>
                                            Create New User
                                        </button>
                                    </span>
                                </CustomTooltip>
                            )}
                        </div>
                    )}

                    <div className="col-3 ms-auto">
                        <GlobalSearchBar inPageSearchCallback={setSearchTerm} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <ManageCustomersTable
                    tableId="org-users-table"
                    tableClassName="mb-5"
                    isTableRowsClickable={false}
                    idKey="userId"
                    columnDetails={tableColumnDetails.filter(tableColumn => tableColumn.show)}
                    data={displayUsers}
                    sortClickCallback={sortClickCallback}
                    rowClickCallback={null}
                    selected={selectedUsers}
                    setSelected={setSelectedUsers}
                    ellipsisColumnIndex={2}
                    enableSelectingRows={true}
                    filterChangeCallback={filterChangeCallback}
                    selectedFilters={selectedFilters}
                    options={{
                        checkboxes: true,
                        disableCheckboxFn: e => {
                            return e.emailConfirmed || e.isDeactivated;
                        },
                    }}
                />
                <UsersActivationEmailFooter show={shouldShowFooter} onSend={openBulkSendConfirmationModal} />
            </div>
            <Confirmation ref={confirmRef} />
        </div>
    );
};

export default OrganizationUsers;
